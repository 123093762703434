import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'poc-receipt';
  isProduction = environment.production;

  onThemeChange = (theme: string) => {
    // get the selected theme's CSS file
    const themeCss = `./${theme}.css`;

    // remove any existing theme CSS files
    const links = this.document.head.querySelectorAll('link[rel="stylesheet"][data-app-theme]');
    links.forEach(link => link.remove());

    // add the new theme CSS file
    const linkEl = this.document.createElement('link');
    linkEl.setAttribute('rel', 'stylesheet');
    linkEl.setAttribute('href', themeCss);
    linkEl.setAttribute('data-app-theme', '');

    this.document.head.appendChild(linkEl);
  }

  constructor(
    private elementRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }


}
