import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule, Routes } from '@angular/router';
import { AppComponent } from '@app/app.component';
import { IsAuthenticated, IsUnauthenticated } from '@app/auth/auth.guard';
import { AuthInterceptor } from '@app/auth/auth.interceptor';
import { AuthService } from '@app/auth/auth.service';
import { HandshakeService } from '@core/handshake';
import { environment } from '@environments/environment';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { initializeLanguage, initializeSupportedLocales } from './app.initializers';
import { ForbiddenComponent } from './modules/error/forbidden/forbidden.component';
import { MaintenanceComponent } from './modules/error/maintenance/maintenance.component';
import { NotFoundComponent } from './modules/error/not-found/not-found.component';
import { ErrorhandlerService } from './shared/errorhandler.service';
import { InternalServerErrorComponent } from './modules/error/internal-server-error/internal-server-error.component';

// only valid in non-production version
const sampleRoutes = environment.production ? [] : [
  { path: 'sample', loadComponent: () => import('./modules/sample/sample.component').then(mod => mod.SampleComponent) },
]

const routes: Routes = [
  { path: 'auth', canMatch: [IsUnauthenticated], loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule) },
  { path: 'portal', canMatch: [IsAuthenticated], loadChildren: () => import('./modules/portal/portal.module').then(m => m.PortalModule) },
  ...sampleRoutes,
  { path: 'error/forbidden', loadComponent: () => import('./modules/error/forbidden/forbidden.component').then(mod => mod.ForbiddenComponent) },
  { path: 'error/not-found', loadComponent: () => import('./modules/error/not-found/not-found.component').then(mod => mod.NotFoundComponent) },
  { path: 'error/maintenance', loadComponent: () => import('./modules/error/maintenance/maintenance.component').then(mod => mod.MaintenanceComponent) },
  { path: 'error/internal-server-error', loadComponent: () => import('./modules/error/internal-server-error/internal-server-error.component').then(mod => mod.InternalServerErrorComponent) },
  { path: '**', redirectTo: 'portal', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes),
    NgbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthService, HandshakeService, Router] },
    // { provide: LOCALE_ID, useValue: 'vi'}
    { provide: LOCALE_ID, useFactory: initializeSupportedLocales },
    { provide: APP_INITIALIZER, useFactory: () => initializeLanguage, multi: true },
    { provide: ErrorHandler, useClass: ErrorhandlerService },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
