import { Router } from '@angular/router';
import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService implements ErrorHandler {

  constructor(private router: Router) { }
  handleError(error: any): void {
    // console.log(error);
    if (error.message == '401') {
      this.router.navigateByUrl('/error/forbidden');
      return;
    } else if (error.message == '404') {
      this.router.navigateByUrl('/error/not-found');
      return;
    }
    throw error;
  }
}
