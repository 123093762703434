import { CanMatchFn, Route, Router, UrlSegment } from '@angular/router';
import { Role } from './roles';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { map } from 'rxjs';

export const IsUnauthenticated: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.IsAuthenicatedAsync().pipe(
    map(isAuthenticated => {
      if (!isAuthenticated) {
        return true;
      }
      return router.createUrlTree(['/portal']);
    })
  );
}

export const IsAuthenticated: CanMatchFn = (route: Route, segments: UrlSegment[]) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  return authService.IsAuthenicatedAsync().pipe(
    map(isAuthenticated => {
      if (!isAuthenticated) {
        return router.createUrlTree(['/auth/login']);
      }
      return true;
    })
  );
}

export const HasAnyRole = (...roles: Role[]): CanMatchFn =>
  (route: Route, segments: UrlSegment[]) => {
    const router = inject(Router);
    const authService = inject(AuthService);
    return authService.IsHasAnyRoleAsync(...roles).pipe(
      map(isMatch => {
        if (!isMatch) {
          return router.createUrlTree(['/portal/error/forbidden']);
        }
        return true;
      })
    );
  }

export const HasRole = (role: Role): CanMatchFn => HasAnyRole(role);
