import { loadTranslations } from '@angular/localize';
import { registerLocaleData } from '@angular/common';
import localeVi from '@angular/common/locales/vi';
import en from '@locale/messages.en.json';
import vi from '@locale/messages.vi.json';

export const initializeLanguage = (): Promise<void> | void => {
  const language = localStorage.getItem('language') ?? 'vi';
  if (language && language !== 'vi') {
    loadTranslations(en.translations);
  } else {
    loadTranslations(vi.translations);
  }
}

export const initializeSupportedLocales = () => {
  registerLocaleData(localeVi, 'vi');
  const language = localStorage.getItem('language') ?? 'vi';
  document.documentElement.lang = language;
  return language;
}
