export enum Role {
  User = 'User',
  Administrators = 'Administrators',
  SYS_StaffUser = 'SYS_StaffUser',
  SYS_MerchantUser = 'SYS_MerchantUser',
  SYS_DeviceForceLogon = 'SYS_DeviceForceLogon',
  SYS_CardBIN = 'SYS_CardBIN',
  SYS_MerchantCategoryCode = 'SYS_MerchantCategoryCode',
  SYS_BankInfo = 'SYS_BankInfo',
  DB_DashboardMonitor = 'DB_DashboardMonitor',
  DB_DashboardMonitorExport = 'DB_DashboardMonitorExport',
  UM_ResetPassword = 'UM_ResetPassword',
  UM_Administrator = 'UM_Administrator',
  UM_UserView = 'UM_UserView',
  UM_UserExport = 'UM_UserExport',
  UM_UserUpdateProfile = 'UM_UserUpdateProfile',
  UM_UserUpdatePassword = 'UM_UserUpdatePassword',
  UM_UserUpdateRole = 'UM_UserUpdateRole',
  UM_UserUpdateStatus = 'UM_UserUpdateStatus',
  UM_UserCreate = 'UM_UserCreate',
  UM_UserUpdateBranch = 'UM_UserUpdateBranch',
  MM_MerchantView = 'MM_MerchantView',
  MM_MerchantOnboarding = 'MM_MerchantOnboarding',
  MM_MerchantUpdate = 'MM_MerchantUpdate',
  RP_ReportMerchant = 'RP_ReportMerchant',
  UM_BranchView = 'UM_BranchView',
  DevelopmentUnit_Add = 'DevelopmentUnit_Add',
  DevelopmentUnit_ConfigPolicyRouting = 'DevelopmentUnit_ConfigPolicyRouting',
  POS_view = 'POS_view',
  POS_Update = 'POS_Update',
  POS_Create = 'POS_Create',
  Account_Management = 'Account_Management',
  TM_TerminalView = 'TM_TerminalView',
  TM_TerminalCreate = 'TM_TerminalCreate',
  TM_TerminalUpdateProfile = 'TM_TerminalUpdateProfile',
  TM_TerminalUpdateStatus = 'TM_TerminalUpdateStatus',
  Terminal_Import = 'Terminal_Import',
  Terminal_Approve = 'Terminal_Approve',
  TM_TerminalRequest = 'TM_TerminalRequest',
  ER_EReceiptResend = 'ER_EReceiptResend',
  ER_EReceiptView = 'ER_EReceiptView',
  MP_TransDifferenceUpload = 'MP_TransDifferenceUpload',
  MP_TransDifferenceView = 'MP_TransDifferenceView',
  MP_TransDifferenceApprove = 'MP_TransDifferenceApprove',
  RF_RefundCreate = 'RF_RefundCreate',
  RF_RefundProcess = 'RF_RefundProcess',
  RF_RefundView = 'RF_RefundView',
  API_Refund = 'API_Refund',
  RP_ReportSettlement = 'RP_ReportSettlement',
  RP_ReportOnline = 'RP_ReportOnline',
  RP_ReportGeneral = 'RP_ReportGeneral',
  RP_ReportRefund = 'RP_ReportRefund',
  RP_ReportOperation = 'RP_ReportOperation',
  RP_ReportSale = 'RP_ReportSale',
  MP_AutoSettlementConfigView = 'MP_AutoSettlementConfigView',
  MP_AutoSettlementConfigApprove = 'MP_AutoSettlementConfigApprove',
  MP_AutoSettlementConfigUpdate = 'MP_AutoSettlementConfigUpdate',
  MP_AutoSettlementConfigCreate = 'MP_AutoSettlementConfigCreate',
  MP_AutoSettlementConfigRemove = 'MP_AutoSettlementConfigRemove',
  MP_SettlementManual = 'MP_SettlementManual',
  MP_SettlementManualView = 'MP_SettlementManualView',
  TPL_TemplateView = "TPL_TemplateView",
  TPL_TemplateCreate = "TPL_TemplateCreate",
  TPL_TemplateUpdate = "TPL_TemplateUpdate",
}
