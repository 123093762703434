/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth/auth.service';
import { ApiRequest, buildRequest } from '@core/api';
import { DecryptResponse, EncryptRequest, Sign } from '@core/crypto.helper';
import { HandshakeService } from '@core/handshake';
import { Logger } from '@app/utils/log';
import { Observable, catchError, from, map, mergeMap, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { LocalStorageKey } from '@core/local-storage.helper';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private handshakeService: HandshakeService,
    private readonly router: Router,
  ) {
    const lang = localStorage.getItem(LocalStorageKey.CURRENT_LANG);
    this._currentLang = lang === 'en' ? 'en' : 'vi';
  }

  private _currentLang: 'vi' | 'en' = 'vi';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // exclude handshake
    if (request.url.endsWith('/auth/handshake')) {
      const session = localStorage.getItem(LocalStorageKey.SESSION_ID);
      if (session) {
        const update: any = {
          setHeaders: {
            'X-Session-ID': session,
          },
          body: request.body,
          responseType: request.responseType,
        }
        const jwt = this.authService.AccessToken;
        if (jwt) {
          update.setHeaders.Authorization = `Bearer ${jwt}`;
        }
        request = request.clone(update);
      }
      return next.handle(request);
    }

    return this.handshakeService.handshake().pipe(
      mergeMap(handshakeData => {
        if (!handshakeData) {
          Logger.log('Handshake fail', request.method, request.url);
          throw new Error('Handshake fail');
        }

        const isFormData = request.body instanceof FormData;
        let body: ApiRequest<any>;
        if (isFormData)
          body = buildRequest(JSON.parse(request.body.get('Data')) ?? {}, this._currentLang)
        else
          body = buildRequest(request.body, this._currentLang);

        const encryptedBody = EncryptRequest(JSON.stringify(body), handshakeData.SessionKey, handshakeData.IV);
        const Signature = Sign(encryptedBody, handshakeData.Salt);

        if (isFormData) {
          (request.body as FormData).set('Data', encryptedBody);
        }

        const update: any = {
          reportProgress: false,
          setHeaders: {
            'X-Session-ID': handshakeData.SessionId,
            Signature,
          },
          body: isFormData ? request.body : encryptedBody,
          responseType: request.responseType == 'json' ? 'text' : request.responseType,
        };
        if (!request.url.includes('/auth/')) {
          const jwt = this.authService.AccessToken;
          update.setHeaders.Authorization = `Bearer ${jwt}`;
        }
        Logger.log('Send', request.method, request.url, body);
        request = request.clone(update);
        return next.handle(request).pipe(
          map((response: any) => {
            if (response.body) {
              const headers = response.headers as HttpHeaders
              const contentType = headers.get('Content-Type')
              if (contentType?.includes('application/json')) {
                response.body = JSON.parse(DecryptResponse(response.body, handshakeData.SessionKey, handshakeData.IV));
                Logger.log('Receive', request.method, request.url, response.body);
                if (response.body?.ResponseCode === '403') {
                  return from(this.router.navigate(['/error/forbidden'])).pipe(mergeMap(_ => of(null)));
                }
                // please consider abount this, not found or not exists 404 will redirect page to not found page
                if (response.body?.ResponseCode === '404') {
                  return from(this.router.navigate(['/error/not-found'])).pipe(mergeMap(_ => of(null)));
                }
                if (['500', '505'].includes(response.body?.ResponseCode)) {
                  return from(this.router.navigate(['/error/internal-server-error'])).pipe(mergeMap(_ => of(null)));
                }
                if (['501', '502', '503', '504'].includes(response.body?.ResponseCode)) {
                  return from(this.router.navigate(['/error/maintenance'])).pipe(mergeMap(_ => of(null)));
                }

              } else {
                Logger.log('Receive', request.method, request.url);
              }
            }
            return response;
          }),
          catchError((error: HttpErrorResponse) => {
            Logger.log('Error', request.method, request.url, error);
            if (error.status === 401) {
              return from(this.authService.logout(true)).pipe(mergeMap(_ => of(null)));
            }
            if (error.status === 403) {
              return from(this.router.navigate(['/error/forbidden'])).pipe(mergeMap(_ => of(null)));
            }
            if (error.status === 404) {
              return from(this.router.navigate(['/error/not-found'])).pipe(mergeMap(_ => of(null)));
            }
            if ([500, 505].includes(error.status)) {
              return from(this.router.navigate(['/error/internal-server-error'])).pipe(mergeMap(_ => of(null)));
            }
            if ([501, 502, 503, 504].includes(error.status)) {
              return from(this.router.navigate(['/error/maintenance'])).pipe(mergeMap(_ => of(null)));
            }
            // return of(error);
            return throwError(() => error)
          })
        );
      })
    )
  }
}
