import { Injectable } from '@angular/core';

export enum LocalStorageKey {
  ACCESS_TOKEN = 'AccessToken',
  REFRESH_TOKEN = 'RefreshToken',
  SESSION_ID = 'X-SESSIO-ID',

  CURRENT_USER = 'CurrentUser',
  CURRENT_LANG = 'language',
  REFESH_INTERVAL = 'RefreshInterval'
}

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  localStorage!: Storage;
  isEnabled = false;

  constructor() {
    if (!window.localStorage) {
      this.isEnabled = false;
      console.error('Current browser does not support Local Storage');
      return;
    }
    this.isEnabled = true;
    this.localStorage = window.localStorage;
  }

  set(key: LocalStorageKey, value: string): void {
    if (this.isEnabled) {
      this.localStorage.setItem(key, value);
    }
  }

  get(key: LocalStorageKey): string {
    if (!this.isEnabled) {
      return '';
    }

    return this.localStorage.getItem(key) || '';
  }

  setObject(key: LocalStorageKey, value: unknown): void {
    if (!this.isEnabled) {
      return;
    }
    this.localStorage.setItem(key, JSON.stringify(value));
  }

  getObject<TType = unknown>(key: LocalStorageKey): TType | null {
    if (!this.isEnabled) {
      return null;
    }
    const value = this.localStorage.getItem(key);
    if (!value) return null;
    return JSON.parse(value) as TType;
  }

  remove(key: LocalStorageKey): void {
    if (!this.isEnabled) {
      return;
    }
    this.localStorage.removeItem(key);
  }

  clear(): void {
    this.localStorage.clear();
  }

  clearLogin(): void {
    this.remove(LocalStorageKey.ACCESS_TOKEN);
    this.remove(LocalStorageKey.CURRENT_USER);
    this.remove(LocalStorageKey.SESSION_ID);
    this.localStorage.removeItem("key");
  }
}
