import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as NodeRSA from 'node-rsa';

const ttl = 86400*1000*30;

function generateKeyPair() {
  const rsa = new NodeRSA();
  rsa.setOptions({ encryptionScheme: 'pkcs1' });
  return rsa.generateKeyPair(2048, 65537);
}

interface Key {
  pri: string;
  exp: number;
}

@Injectable({ providedIn: 'root' })
export class RSAHelper {
  private _keyPair!: NodeRSA;
  public readonly PublicKey!: string;

  constructor() {
    const key: Key = JSON.parse(window.localStorage.getItem('key') as string);
    if (key?.exp > Date.now()) {
      this._keyPair = new NodeRSA(key.pri);
      this._keyPair.setOptions({ encryptionScheme: 'pkcs1' });
    }
    else {
      this._keyPair = new NodeRSA();
      this._keyPair.setOptions({ encryptionScheme: 'pkcs1' });
      this._keyPair.generateKeyPair(2048, 65537);
      const key: Key = {
        exp: Date.now() + ttl,
        pri: this._keyPair.exportKey()
      };
      window.localStorage.setItem('key', JSON.stringify(key));
    }

    this.PublicKey = this._keyPair.exportKey('pkcs1-public-der').toString('hex');
  }

  public decrypt(hexData: string, outputEncode: NodeRSA.Encoding = 'hex'): string {
    return this._keyPair.decrypt(Buffer.from(hexData, 'hex'), outputEncode);
  }
}

export const Sign = (data: string | unknown, salt: string, outputEncode = CryptoJS.enc.Hex) => {
  const jsonStr = typeof (data) === 'string' ? data : JSON.stringify(data);
  return CryptoJS.SHA512(jsonStr + salt).toString(outputEncode)
}

export const EncryptRequest = (data: string, key: string, iv: string, format = CryptoJS.enc.Hex) => {
  if (!data) return '';

  return CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Hex.parse(key),
    {
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC,
      iv: CryptoJS.enc.Hex.parse(iv)
    }
  ).ciphertext.toString(format);
}

export const DecryptResponse = (data: string, key: string, iv: string) => {
  if (!data) return '';
  return CryptoJS.AES.decrypt(
    { ciphertext: CryptoJS.enc.Hex.parse(data) } as CryptoJS.lib.CipherParams,
    CryptoJS.enc.Hex.parse(key),
    {
      padding: CryptoJS.pad.Pkcs7,
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
    }
  ).toString(CryptoJS.enc.Utf8);
}
