import { AuthService } from './../app/auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Logger } from '@app/utils/log';
import { environment } from '@environments/environment';
import { Observable, ReplaySubject, catchError, finalize, from, mergeMap, of } from 'rxjs';
import { ApiResponse, buildRequest } from './api';
import { RSAHelper } from './crypto.helper';
import { LocalStorageKey, LocalStorageService } from './local-storage.helper';

export interface HandshakeResponseData {
  Salt: string;
  SessionKey: string;
  IV: string;
}

export type HandshakeData = HandshakeResponseData & { SessionId: string };

@Injectable({
  providedIn: 'root'
})
export class HandshakeService {
  private IsHandshaking = false;
  private HandshakeData$ = new ReplaySubject<HandshakeData>(1);
  private HandshakeData!: HandshakeData;

  constructor(
    private http: HttpClient,
    private rsaHelper: RSAHelper,
    private readonly localStorageService: LocalStorageService,
    private authService: AuthService,
  ) {
  }

  handshake(): Observable<HandshakeData> {
    if (this.HandshakeData) {
      Logger.log('Handshake: Ready');
      return of(this.HandshakeData);
    }

    const session = this.localStorageService.get(LocalStorageKey.SESSION_ID);
    if (!this.IsHandshaking) {
      Logger.log('Handshake: Start');
      this.IsHandshaking = true;
      // Get the public key in hexadecimal format
      const PublicKey = this.rsaHelper.PublicKey.toUpperCase();
      const data = buildRequest({ PublicKey, SessionID: session });
      this.http.post<ApiResponse<HandshakeResponseData>>(`${environment.apiUrl}/auth/handshake`, data)
        .pipe(
          finalize(() => {
            this.IsHandshaking = false;
          }),
          catchError((err) => { return from(this.authService.logout(true)).pipe(mergeMap(_ => of(null))) })
        )
        .subscribe((response) => {
          const SessionKey = this.rsaHelper.decrypt(response!.Data.SessionKey);
          const Salt = this.rsaHelper.decrypt(response!.Data.Salt, 'ascii');
          const IV = this.rsaHelper.decrypt(response!.Data.IV);
          const SessionId = session != undefined && session != '' ? session : data.RequestID;
          this.HandshakeData = { SessionId, SessionKey, IV, Salt };
          this.localStorageService.set(LocalStorageKey.SESSION_ID, SessionId);
          this.HandshakeData$.next(this.HandshakeData);
          Logger.log('Handshake: Done');
        });
    }

    Logger.log('Handshake: In progress');
    return this.HandshakeData$;
  }

}
